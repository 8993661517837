// 组织架构
<template>
  <div class="company-organizationChart ">
    <h3 class="organizationChart-title">组织架构</h3>
    <img class="organizationChart-img" :src="require('@/assets/images/gsgk-img1.png')" />
    <Info class="organizationChart-info flex-center" />
  </div>
</template>

<script>
import Info from '@/components/Info'
export default {
  name: 'OrganizationChart',
  components: {
    Info
  },
};
</script>

<style scoped lang="less">
.organizationChart-title {
  text-align: center;
  font-size: 24px;
  color: #e6ac44;
  font-weight: bold;
}
.organizationChart-img {
  width: 100%;
  margin: 36px 0 50px -4px;
}
.organizationChart-info {
  /deep/ p {
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
